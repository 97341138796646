@import '../../../../scss/core/vars';

.list {
  &-container {
    > .bulk-actions {
      margin-bottom: 25px;
    }

    &__content {
      &--grid &__item {
        display: initial;

        .check-box {
          left: 15px;
          position: absolute;
          top: 15px;
          z-index: 5;
        }
      }

      &:not(&--grid) {
        display: flex;
        flex-direction: column;
      }

      &:not(&--grid) &__item,
      > .list-placeholder {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__item {
        align-items: center;
        border-radius: 10px;
        box-sizing: border-box;
        display: flex;

        > *:last-child {
          box-sizing: border-box;
          flex: 1;
          position: relative;
        }

        &--selected {
          > *:last-child {
            &::before {
              border: 2px solid $monkey;
              border-radius: inherit;
              box-sizing: border-box;
              content: '';
              height: 100%;
              left: 0;
              pointer-events: none;
              position: absolute;
              top: 0;
              width: 100%;
              z-index: 5;
            }
          }
        }

        > .check-box {
          margin-right: 25px;
        }
      }
    }

    &__infinite-load {
      margin-top: 10px;

      &--grid {
        display: grid !important;
      }
    }
  }

  &__no-data-container {
    padding: 85px;
    text-align: center;

    &--mobile {
      padding: 35px;
    }
  }

  &__no-data {
    &__svg-container {
      box-sizing: border-box;
      height: 250px;
      margin-bottom: 20px;
      width: 100%;

      > svg {
        height: 100%;
        max-width: 100%;
        width: auto;
      }
    }
  }

  &__result-limit {
    align-items: center;
    background: $cat-skill-white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    padding: 50px;

    > p {
      color: $regent;
      margin: 0;
      margin-left: 20px;
    }
  }
}
