@import '~@devsta/ui-kit/scss/core/vars';

.share-modal {
  display: flex;
  max-height: calc(100vh - 10px);
  padding: 40px;

  .rah-static {
    flex: 1;
    overflow: hidden;

    > div:first-of-type {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  &__loader {
    background: $white;
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 20px;
    width: 600px;

    svg {
      max-height: 40vh;
    }
  }

  &__horizontal-line {
    border: 1px solid $russian;
    margin: 0;
    opacity: 0.5;
    width: 100%;
  }

  &__advanced-settings-container {
    display: flex;
  }

  &__advanced-settings {
    overflow-y: auto;
    padding: 20px 0 0;
    transition: visibility 1s;

    &__hide {
      visibility: hidden;
    }
  }

  &__body {
    overflow-x: hidden;
    overflow-y: hidden;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__copy {
      align-items: center;
      display: flex;
      margin-bottom: 15px;

      &__action {
        align-items: center;
        display: flex;
        margin-left: 15px;

        > .text {
          margin-left: 5px;
          white-space: nowrap;
          width: 60px;
        }
      }
    }
  }
}
