@import '../../../../../../scss/core/vars';
@import './mixins';

.action-item {
  &__separator {
    border: 0;
    border-bottom: 1px solid $russian;
    height: 0;
    margin: 10px;
  }

  &__submenu {
    display: flex;
    justify-content: space-between;
    position: relative;

    &-content {
      display: none;
      height: 0;
      overflow: hidden;
      position: absolute;
      top: 100%;
    }

    &:hover > &-content {
      @include show-submenu-content;

      left: 100%;
    }

    &-left:hover > &-content {
      @include show-submenu-content;

      left: -100%;
    }
  }
}
