@import '~@devsta/ui-kit/scss/core/vars';

.card-banner {
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: $shadows-soft;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  min-height: 230px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  &__card {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    padding: 0 3%;
    text-align: center;

    h5 {
      padding-top: 10px;
    }

    &--border-right {
      border-right: 1px solid $michael;
    }
  }

  @media only screen and (max-width : 750px) {
    flex-direction: column;

    &__card {
      margin-top: 40px;
      padding-bottom: 40px;

      &--border-right {
        border-bottom: 1px solid $michael;
        border-right: 0;
      }
    }
  }
}
