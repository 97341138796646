@import '~@devsta/ui-kit/scss/core/vars';
$min-width: 280px;

.addon-management-modal {
  &.modal {
    padding: 40px;
  }

  .modal {
    &__content {
      overflow-y: auto !important;
    }

    &__footer {
      margin-top: 110px;

      .button--tertiary {
        background-color: $monkey;

        .text--button {
          color: $white;
        }
      }
    }
  }

  .text--placeholder {
    color: $black;
    font-weight: $font-weights-semibold;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  &__container {
    .outputs-and-addons {
      overflow-y: hidden;
      padding-bottom: 0;

      &__section {
        padding: 0;

        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }
    }

    .rich-editor {
      &__editor {
        min-height: 175px;
      }
    }

    .expandable-addon {
      border-bottom: 1px solid $russian;
      padding-bottom: 40px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }

      &__wrapper {
        display: flex;
        justify-content: space-between;
      }

      &__main {
        display: block;
      }

      .erd {
        &_scroll_detection_container {
          display: none !important;
        }
      }

      &__dropdown-container {
        .dropdown--btn {
          max-width: 385px;
          width: 100%;
        }
      }

      &__checkbox {
        display: flex;
      }
    }

    .social-iterations {
      &__main {
        justify-content: space-between;
      }

      .social-iteration {
        &__configure {
          margin-left: 10px;
        }
      }
    }

    .outputs-and-addons {
      &.column {
        padding: 0;
      }
    }
  }

  .outputs-and-addons {
    &__final-cost {
      bottom: 130px;
      display: flex;
      justify-content: flex-end;
      margin: 0;
      position: absolute;
      width: calc(100% - 80px);

      > h5 {
        margin-right: 30px;
      }
    }
  }

  @media screen and (max-width: $media-sizes-medium) {
    &.modal {
      padding: 20px;

      &__final-cost {
        width: calc(100% - 40px);
      }
    }

    .expandable-addon {
      &__wrapper {
        flex-direction: column;
      }

      .edit-cost {
        margin-left: 40px;
      }
    }

    .multiple-dropdown {
      &__item {
        .edit-cost {
          margin-left: 0;
        }
      }
    }
  }

  @media screen and (min-width: $media-sizes-medium) {
    &.modal {
      width: 820px;
    }
  }
}
