@import './data-table-mixins';

.data-table-body-cell {
  font-size: $data-table-body-cell-font-size;

  &__expander {
    margin-right: $data-table-tree-expander-indent;
  }
}



