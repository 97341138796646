@import '~@devsta/ui-kit/scss/core/vars';

.stock-asset-list-card {
  background-color: $white;
  border-radius: 10px;
  box-shadow: $shadows-soft;
  height: 100%;
  height: 300px;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  width: auto;
  z-index: 1;

  &__asset-body {
    cursor: pointer;
    height: 100%;
    position: absolute;
    width: 100%;

    &__video-player {
      height: 65%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      z-index: 2;

      video {
        left: 50%;
        max-height: 112%;
        max-width: 112%;
        min-height: 56.25vw; /* 100 * 9 / 16 */
        min-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 177.77777778vh; /* 100 * 16 / 9 */
        z-index: 1;
      }

      .video-progress-bar {
        background-color: $turquoise;
        height: 100%;
        left: 0%;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 3px;
        z-index: 2;
      }
    }

    &__image {
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 65%;
      position: relative;
      z-index: 1;

      .asset-type-icon {
        background-color: $black-10;
        border-radius: 4px;
        bottom: 5px;
        padding: 6px 8px;
        position: absolute;
        right: 15px;
      }
    }

    &__details {
      background-color: $white;
      height: 35%;
      overflow: hidden;
      padding-left: 45px;
      padding-top: 15px;
      position: relative;
      white-space: nowrap;
      width: 100%;
      z-index: 3;

      &__title {
        font-size: $font-sizes-semilarge;
        font-weight: $font-weights-medium;
        left: 0;
        position: relative;
        width: 30%;
      }

      .asset-origin-icon {
        height: 20px;
        left: 15px;
        position: absolute;
        top: 25px;
        width: 20px;
      }

      &__contributor {
        color: $nevada;
        margin-left: -30px;
      }
    }

    &__asset-options-button {
      bottom: 30px;
      position: absolute;
      right: 25px;
      width: 30px;
      z-index: 4;
    }

  }

  .video-duration {
    background-color: $black;
    border-radius: 4px;
    color: $white;
    display: inline-block;
    font-size: $font-sizes-small;
    left: 12px;
    min-width: 30px;
    padding: 3px 8px;
    position: absolute;
    text-align: center;
    top: 150px;
    z-index: 310;
  }

  &--hide-card-details {
    height: 200px;

    .stock-asset-list-card {
      &__asset-body {
        &__video-player,
        &__image {
          height: 100%;
        }

        &__details,
 
        &__asset-options-button {
          display: none;
        }
      }
    }
  }

}

.enable-asset-cards {
  pointer-events: auto;
}

.stock-asset-list-card {
  &--not-stock-asset-type {
    &__asset-body {
      &__details {
        padding-left: 20px;

        .asset-origin-icon {
          display: none;
        }

        &__contributor {
          margin-left: 0;
        }
      }
    }
  }
}
