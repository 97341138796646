.switch-list {
  &__title + &__item {
    margin-top: 8px;
  }

  &__item + &__item {
    margin-top: 14px;
  }

  &__item {
    .text.text--label {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }
  }
}
