@import '~@devsta/ui-kit/scss/core/vars';

.comment-content {
  margin-bottom: 25px;
  margin-top: 15px;
  overflow: visible;

  &__editor {
    position: relative;

    .rich-editor {
      &__editor {
        max-height: 200px;
        padding-right: 35px;
      }
    }

    > p {
      color: $regent;
      font-size: $font-sizes-small;
      font-weight: $font-weights-medium;
      line-height: $font-sizes-small;
      margin-bottom: 7px;
    }

    &__icons-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      > *:first-child {
        margin-right: 5px;
      }
    }
  }
}
