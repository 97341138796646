@import '../../../../scss/core/vars';

.nav-menu-mobile {
  &__menu {
    > button {
      margin-left: 5px;
    }

    &__org {
      align-items: center;
      display: flex;
      padding-left: 20px;

      > .text {
        margin-left: 20px;
      }
    }

    &__links {
      > button:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }

    .dropdown {
      margin-left: 5px;
      width: 40px;

      .react-select {
        &__control {
          border-radius: 6px;
        }

        &__value-container {
          padding: 0 9px !important;
        }
      }
    }
  }
}
