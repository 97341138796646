@import '~@devsta/ui-kit/scss/core/vars';

$modal-width: 460px;

.get-in-touch-modal {
  box-sizing: border-box;

  .rodal-dialog {
    max-width: $modal-width !important;

    .modal {
      padding: 45px 50px 35px;
    }
  }

  &__title {
    color: $black;
    font-size: $font-sizes-large;
    font-weight: $font-weights-medium;
    margin-bottom: 15px;
    text-align: center;
  }

  &__subtitle {
    color: $black;
    margin-bottom: 25px;
    text-align: center;
  }

  &__reasons {
    list-style-type: none;
    margin: 10px 0 15px;
    padding: 0;

    &__item {
      margin-bottom: 15px;
    }
  }

  &__other-reason {
    margin-bottom: 15px;

    textarea {
      font-family: 'Poppins', sans-serif;
    }
  }

  &__btn,
  &__cancel-btn {
    width: 100% !important;
  }

  &__btn {
    margin-bottom: 15px;
  }

  &--submitted {
    .modal {
      &__header {
        display: none;
      }

      &__content {
        align-items: center;
      }

      .submitted-image {
        margin: 30px 0;
      }
    }
  }
}
