@import '../../../common/styles/mixins/button';

$sort-icon-content-indent-horizontal: 7px;

.sort {
  @include button-reset-styles;

  align-items: center;
  display: flex;
  text-align: inherit;

  &__title + &__icons {
    margin-left: $sort-icon-content-indent-horizontal;
  }

  &:hover {
    cursor: pointer;
  }
}
