@import '~@devsta/ui-kit/scss/core/vars';

$small-radius: 8px;

.social-iteration {
  align-items: flex-start;
  display: flex;

  &__configure {
    flex: 1;

    &__header,
    &__footer {
      display: flex;
      flex-wrap: wrap;
    }

    &__header {
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 20px;

      &__fields {
        align-items: flex-end;
        display: flex;
        flex: 1;
        margin-right: 40px;

        > *:first-child {
          margin-right: 20px;
        }
      }
    }

    &__footer {
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  &__preview-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 40px;
    margin-top: 10px;

    .edit-cost {
      margin-top: 20px;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 40px;
  }

  &__preview {
    align-items: center;
    background: $monkey;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 175px;

    &::before {
      background: $denim;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      bottom: 0;
      content: '';
      position: absolute;
      width: calc(100% - 2px);
    }

    &::after {
      background: $denim;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      content: '';
      position: absolute;
      top: 0;
      width: calc(100% - 2px);
    }

    &--rotated {
      height: 90px;

      &::before {
        border-radius: 0;
        border-bottom-left-radius: $small-radius;
        border-top-left-radius: $small-radius;
        bottom: unset;
        height: calc(100% - 2px);
        left: 0;
        width: unset;
      }

      &::after {
        border-radius: 0;
        border-bottom-right-radius: $small-radius;
        border-top-right-radius: $small-radius;
        height: calc(100% - 2px);
        right: 0;
        top: unset;
        width: unset;
      }

      > .icon-container:first-of-type {
        transform: rotate(90deg);
      }
    }

    > .icon-container:last-of-type {
      position: absolute;
      //left: 7px;
    }

    &--WIDE {
      &::before,
      &::after {
        width: 18px;
      }
    }


    &--STANDARD {
      &::before,
      &::after {
        width: 37px;
      }
    }

    &--INSTA {
      width: 90px;

      &::before,
      &::after {
        height: 20px;
      }
    }

    &--SQUARE {
      &::before,
      &::after {
        width: 44px;
      }
    }
  }

  &.mobile {
    .social-iteration {
      &__preview-container {
        margin: 0 auto;
        padding: 10px 0;
      }

      &__configure {
        &__header {
          &__fields {
            margin: 0;
          }
        }

        &__footer {
          justify-content: space-between;

          > * {
            margin-bottom: 20px;
          }
        }
      }

      .remove-iteration {
        display: none;
      }
    }
  }

  @media screen and (max-width: $media-sizes-medium) {
    flex-direction: column;

    &__preview-container {
      margin-right: 0;
      max-width: 100%;
    }

    &__configure {
      &__header {
        align-items: flex-start;

        > button {
          margin-top: 38px;
          z-index: 2;
        }

        &__fields {
          flex-wrap: wrap;

          > *,
          > *:first-child,
          > *:last-child {
            margin: 10px 0 0;
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
