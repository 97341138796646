@import './data-table-mixins';

.data-table {
  $root: &;

  display: flex;
  flex-direction: row;
  overflow: auto;

  &,
  * {
    box-sizing: border-box;
  }

  &--primary {
    @include data-table-outer-indent(
      $data-table-primary-outer-indent-top,
      $data-table-primary-outer-indent-right,
      $data-table-primary-outer-indent-bottom,
      $data-table-primary-outer-indent-left
    );
  }

  &--secondary {
    height: $data-table-secondary-height;

    #{$root}__content {
      height: 100%;
    }
  }

  &--empty {
    display: block;
  }

  &__content {
    border-collapse: separate;
    border-spacing: 0;
    display: inline-table;
    width: 100%;
  }
}
