@import '~@devsta/ui-kit/scss/core/vars';

.comments-list {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 400px;
  overflow-y: auto;
  padding: 25px 20px;

  &__comment {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    padding: 5px 0;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      height: auto;

      > .asset-preview {
        margin-bottom: 20px;
      }

      &__content-wrapper {
        padding-left: 0;
      }
    }

    &__content-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-left: 20px;

      &__heading {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__time {
          font-weight: $font-weights-bold;
          line-height: 20px;
        }
      }

      > .content-view {
        line-height: 20px;
        margin-bottom: 15px;
      }

      &__footer {
        display: block;
      }
    }
  }

  &__empty {
    padding: 30px 25px;
  }
}
