.dropdown-selected-item {
  &__container {
    align-items: center;
    display: flex;
    min-height: 40px;

    &--icon {
      display: flex;
      margin-right: 10px;
    }

    &--label {
      font-size: 14px;
    }

    > div:last-of-type {
      margin-left: auto;
    }
  }
}
