@import '~@devsta/ui-kit/scss/core/vars';

.rodal-dialog {
  min-width: auto !important;
}

.download-app-modal {
  max-width: 600px;

  &__options-container {
    border: 1px solid $russian;
    border-radius: 4px;
    margin: 40px 0 20px;

    &__option {
      margin: 15px;
      padding: 0 15px;

      @media screen and (max-width: $media-sizes-small) {
        padding: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $russian;
        padding: 0 15px 15px;
      }

      &__body,
      &__footer {
        align-items: center;
        display: flex;
        justify-content: space-between;

        > div:last-child {
          display: flex;
          justify-content: center;
          min-width: 160px;
          @media screen and (max-width: $media-sizes-small) {
            min-width: auto;
            padding: 0;
            text-align: center;
          }
        }
      }

      &__body {
        align-items: center;
        display: flex;

        &__icon {
          margin-left: 15px;

          .icon-wrapper {
            background-color: $cast;
            border-radius: 2px;
            padding: 4px;
            width: 32px;
          }
        }

        &__description {
          padding: 0 30px;

          @media screen and (max-width: $media-sizes-small) {
            flex-direction: column;
            padding: 0 0 0 10px;
          }
        }

        &__action {
          > *:not(:last-child) {
            margin-bottom: 15px;
          }

          @media screen and (max-width: $media-sizes-small) {
            display: none !important;
          }

          &__mobile {
            display: none;

            @media screen and (max-width: $media-sizes-small) {
              display: flex;
              justify-content: center;
              margin: 10px 0;
            }
          }
        }
      }

      &__footer {
        margin: 0 160px 0 55px;

        @media screen and (max-width: $media-sizes-small) {
          margin: 0;
        }

        > div {
          padding: 0 30px;
        }

        .link > * {
          font-weight: bold;
        }
      }
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $media-sizes-small) {
      flex-direction: column;
      justify-content: center;

      .text {
        text-align: center;
      }
    }
  }

  .link {
    outline: none;
  }

  .note {
    font-size: $font-sizes-small;
  }
}
