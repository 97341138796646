.date-range {
  .radio-button {
    align-items: baseline;
    margin-bottom: 17px;

    &__button {
      position: relative;
      top: 3px;
    }
  }

  &__button {
    margin-top: 20px;

    button {
      width: 100%;
    }
  }

  &__container {
    outline-color: transparent;
  }

  &__wrapper {
    display: none;

    > div {
      display: flex;
      flex-direction: column;

      label {
        margin-top: 20px;

        &:last-child {
          margin-left: 0 !important;
        }
      }
    }

    &--opened {
      display: flex;
      flex-direction: column;
      margin-left: -40px;
    }

    .date-picker {
      &__block {
        max-width: 245px;
      }
    }
  }
}
