@import '~@devsta/ui-kit/scss/core/vars';

.voice-over-modal {
  .modal {
    max-width: 660px;
  }

  &__subtitle {
    display: block;
  }

  &__items {
    min-width: 600px;
  }
}

@media screen and (max-width: $media-ranges-medium-max) {
  .voice-over-modal {
    &__items {
      margin-top: 0;
      min-width: auto;
    }
  }
}

@media screen and (max-width: $media-ranges-medium-min) {
  .voice-over-modal {
    &__subtitle {
      font-size: $font-sizes-default;
    }

    h3 {
      font-size: $font-sizes-semilarge;
    }
  }
}
