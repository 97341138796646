@import '../../../../scss/core/vars';

$data-table-body-row-background-color: $white;
$data-table-body-row-background-color-hover: $monkey-light;

$data-table-row-indicator-width: 5px;
$data-table-row-indicator-not-active-color: $russian;
$data-table-row-indicator-warning-color: $red-apple;

$data-table-header-cell-font-size: $font-sizes-small;

$data-table-body-cell-font-size: $font-sizes-default;

$data-table-cell-font-size-small: $font-sizes-small;
$data-table-cell-font-size-semilarge: $font-sizes-semilarge;
$data-table-cell-font-weight-bold: $font-weights-semibold;
$data-table-cell-indent-horizontal: 20px;


$data-table-tree-expander-indent: 20px;

// primary style type

// needed to not cut a row shadow when is inside a container
$data-table-primary-outer-indent-top: 0;
$data-table-primary-outer-indent-right: 15px;
$data-table-primary-outer-indent-bottom: 20px;
$data-table-primary-outer-indent-left: $data-table-primary-outer-indent-right;

$data-table-primary-header-row-min-height: 60px;

$data-table-primary-body-row-indent-vertical: 20px;
$data-table-primary-body-row-min-height: $data-table-primary-header-row-min-height;
$data-table-primary-body-row-border-radius: 6px;
$data-table-primary-body-row-box-shadow: $shadows-soft;
$data-table-primary-body-row-root-border-bottom-size: 1px;
$data-table-primary-body-row-root-border-bottom-color: $russian;
$data-table-primary-body-row-child-background-color-odd: $parthenon;
$data-table-primary-body-row-child-background-color-even: $data-table-body-row-background-color;

// secondary style type
$data-table-secondary-height: 600px;

$data-table-secondary-body-border-size: 1px;
$data-table-secondary-body-border-radius: 6px;
$data-table-secondary-body-border-color: $russian;
$data-table-secondary-body-row-min-height: 87px;

$data-table-secondary-header-row-min-height: 30px;

$data-table-secondary-row-indent-vertical: 0;

