@import '~@devsta/ui-kit/scss/core/vars';

.player {
  background: $black;
  display: flex;
  justify-content: center;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
  position: relative;
  width: 100%;

  &:focus {
    outline: 0;
    outline: none;
  }

  &__floating {
    align-items: center;
    background-color: rgb(0, 0, 0);
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    opacity: 1;
    padding-bottom: 20px;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 40px;
    position: relative;
    transition: opacity 0.3s linear;
    width: 100%;
    z-index: 2;

    &--inner {
      background-color: unset;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 1));
      position: absolute;
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }

    &--disabled {
      pointer-events: none;
    }

    &__comments {
      position: absolute;
      top: 15px;
      width: calc(100% - 5%);
    }
  }

  &__video {
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__buffering {
    left: calc(50% - 18px);
    position: absolute;
    top: calc(50% - 40px); /* controllerHeight/2 - loaderHeight/2 */
    transform: translate(-50%, -50%);
  }

  &__control-outside {
    .player {
      &__buffering {
        left: calc(50%);
        top: calc(50% + 20px);
      }
    }
  }

  &__control-bar {
    width: 100%;

    &__play-icon,
    &__skip-icon,
    &__volume-icon,
    &__quality-icon,
    &__def-icon,
    &__fullscreen-icon,
    &__captions-icon,
    &__download-icon,
    &__comments-icon {
      &:hover {
        &::after {
          align-items: center;
          background: rgba(0, 0, 0, 0.8);
          bottom: 90px;
          color: $white;
          content: '';
          display: flex;
          font-size: 10px;
          font-weight: 200;
          height: 30px;
          padding-left: 10px;
          padding-right: 10px;
          position: absolute;
          white-space: nowrap;
        }
      }
    }

    &__download {
      margin-right: 15px;
      @media only screen and (max-width: $media-sizes-small) {
        margin-right: 5px;
      }
    }

    &__play-icon {
      &:hover {
        &::after {
          content: 'Play/Pause ( space )';
          left: 20px;
        }
      }
    }

    &__quality-icon-paths {
      path:not(:first-of-type) {
        display: none;
      }

      path {
        fill: $white !important;
        opacity: 0.75;

        &:nth-of-type(2) {
          fill: #f00 !important;
        }
      }

      &:hover {
        path {
          opacity: 1;
        }
      }
    }

    &__quality-icon {
      &:hover {
        &::after {
          bottom: 67px;
          content: 'Quality';
        }
      }

      &--streaming-hd {
        path:not(:first-of-type) {
          display: block;
        }
      }
    }

    &__def-icon {
      &:hover {
        &::after {
          content: 'Toggle HD';
        }
      }
    }

    &__download-icon {
      &:hover {
        &::after {
          content: 'Download';
        }
      }
    }

    &__comments-icon {
      &:hover {
        &::after {
          content: 'Toggle comments';
        }
      }
    }

    &__skip-icon {
      &:hover {
        &::after {
          content: 'Skip';
        }
      }
    }

    &__volume-icon {
      &:hover {
        &::after {
          content: 'Toggle mute ( m )';
        }
      }
    }

    &__fullscreen-icon {
      &:hover {
        &::after {
          content: 'Toggle fullscreen ( f )';
          right: 20px;
        }
      }
    }

    &__captions-icon {
      &:hover {
        &::after {
          content: 'Toggle captions';
        }
      }
    }

    &__controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 20px;
      transition: opacity 0.3s ease;

      &--loading {
        opacity: 0.5;
        pointer-events: none;
      }

      &__time {
        color: $white;
        font-size: 12px;
        margin: 0;
        margin-left: 15px;
        min-width: 50px;
      }

      &__section {
        align-items: center;
        display: flex;
        flex-direction: row;
        max-width: 50%;
        overflow: hidden;

        .icon-container > svg {
          @media only screen and (max-width: $media-sizes-small) {
            height: 50%;
            width: 50%;
          }
        }

        .icon-container:not(:last-of-type) {
          margin-right: 15px;
          @media only screen and (max-width: $media-sizes-small) {
            margin-right: 5px;
          }
        }
      }

      &__volume-bar {
        /* stylelint-disable property-no-vendor-prefix */
        -webkit-appearance: none;
        margin: 0 5px;
        max-width: 50%;
        min-width: 25%;

        @media only screen and (max-width: $media-sizes-small) {
          margin: 0;
          width: 50%;
        }

        &:focus {
          outline: none;
        }

        &::-webkit-slider-runnable-track {
          background-color: $cerulean;
          cursor: pointer;
          height: 3px;
          width: 50%;
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          background-color: $sterling;
          border-radius: 50%;
          cursor: pointer;
          height: 20px;
          margin-top: -8px;
          width: 20px;
        }
      }
    }
  }
}

.player:fullscreen {
  .comments-overlay {
    display: none !important;
  }
}
