@import '~@devsta/ui-kit/scss/core/vars';

.comment-title {
  display: flex;
  margin: 1em 0;
  max-width: calc(100vw - 300px);

  &__number {
    align-items: center;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-size: $font-sizes-micro;
    font-weight: bold;
    height: 15px;
    justify-content: center;
    line-height: 15px;
    margin-right: 5px;
    min-width: 15px;
    padding-left: 1px;
  }

  &__title {
    font-size: $font-sizes-default;
    font-weight: $font-weights-medium;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
