@import '~@devsta/ui-kit/scss/core/vars';

.social-iterations {
  border-bottom: 1px solid $russian;
  margin-bottom: 30px;
  padding-bottom: 40px;

  &__main {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
  }

  &__children,
  &__subtitle {
    margin-left: 40px;
  }

  &__children {
    margin-top: 20px;
  }

  &__cost {
    margin-left: 40px;
  }

}

@media screen and (max-width: $media-sizes-small) {
  .social-iterations {
    padding-bottom: 20px;

    &__children {
      margin-left: 0;
    }
  }
}
