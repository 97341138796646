@import '~@devsta/ui-kit/scss/core/vars';

.comment-assets {
  &__container {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  &__placeholder {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 1;
  }

  > .action-menu {
    &__btn-container {
      width: 100%;
    }
  }
}

.comment-asset {
  align-items: center;
  border: 1px solid $russian;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 15px;

  > .asset-preview {
    align-self: auto;
  }

  &__title {
    display: flex;
    margin-left: 10px;
    margin-right: auto;
    overflow: hidden;
  }

  > .button,
  > .download-asset-menu {
    margin-left: 10px;

    &-menu {
      bottom: auto;
      left: 0;
      right: auto;
      top: 0;
      transform: translate3d(172px, 188px, 0);
    }
  }
}
