@import '../../../../../../scss/core/vars';

$filter-min-width: 160px;
$filter-max-width: 340px;

.filters {
  &__search {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    &--mobile {
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;

      > .filters__input {
        max-width: unset;
      }
    }

    > .text {
      margin-left: 15px;
      min-width: 100px;
      white-space: nowrap;
      width: 100px;
    }
  }

  &__container {
    margin-bottom: 20px;

    .tabs {
      margin-left: 20px;
      max-height: 50px;
      min-width: 360px;
    }
  }

  &__input {
    max-width: $filter-max-width;
    min-width: $filter-min-width;
  }

  &__dropdown {
    max-width: 100%;
    min-width: $filter-min-width;

    .react-select {
      &__input {
        font-size: $font-sizes-small;
      }

      &__option {
        font-size: $font-sizes-small;
      }

      &__placeholder {
        font-weight: $font-weights-regular;
      }
    }
  }

  & > .menu-button {
    margin-left: auto;
  }
}

// Temporary fix for mobile zoom-in on input focus. Can be removed when default font-size is 16px.
@media only screen and (max-width: $media-sizes-medium) {
  .filters {
    &__dropdown {
      .react-select {
        font-size: $font-sizes-phone;
      }
    }
  }
}