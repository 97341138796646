@import '~@devsta/ui-kit/scss/core/vars';

.asset-list-card {
  .asset-preview {
    min-width: 90px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    &__text {
      max-width: calc(100% - 56px);

      &__title {
        margin-bottom: 5px;
      }

      &__timestamp {
        margin-bottom: 0;
      }
    }

    .action-menu {
      margin-left: 10px;
    }
  }
}

.checkbox-project-brief {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 5;
}
