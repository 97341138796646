@import '~@devsta/ui-kit/scss/core/vars';

.subscription-info-box {
  align-items: center;
  background-color: $white-75;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;

  &__description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;

    &__time {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__cta {
    margin-right: 1em;
  }

  &--medium {
    flex-direction: column;

    .subscription-info-box {
      &__description {
        align-items: center;
        flex-direction: column;
        text-align: center;
      }

      &__cta {
        margin-right: 0;
      }
    }
  }

  &--small {
    flex-direction: row;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;

    .subscription-info-box {
      &__description {
        flex-grow: 0;

        &__time {
          align-items: flex-start;
          flex-direction: column;
          text-align: left;
        
          h5 {
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }
      
      &__cta {
        display: none;
      }
    }
  }
}
