.dropdown-option {
  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &__content {
    flex: 1;
    overflow: hidden;

    &__label {
      display: block;
    }
  }

  &__suffix {
    align-items: center;
    display: flex;
    font-size: 12px;
    margin-left: 10px;
  }

  > .check-box {
    margin-right: 5px;
  }
}
