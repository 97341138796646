.heatmap-cell {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;

  &--clickable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
