@import '../../../../scss/core/vars';

.page-header {
  border-bottom: 1px solid $russian;
  box-sizing: border-box;
  max-height: 100px;
  min-height: 100px;
  width: 100%;

  &__action-content {
    > *:not(:first-child) {
      margin-left: 20px;
    }

    @media only screen and (max-width: $media-sizes-small) {
      margin-left: 20px;
    }

    & .page-header__action-button {
      align-items: center;
      display: flex;
    }
  }
}
