@import '~@devsta/ui-kit/scss/core/vars';

$expanded-max-height: 1000px;
$rolled-up-max-height: 50px;

.payment-section {
  $content-padding: 1.25rem;
  $content-margin-bottom: $content-padding;

  .accordion {
    &__title-wrapper {
      outline: none;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    background-color: $white;
    border: 2px solid $big-fat;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 0.75rem;
    padding: $content-padding;
    transition: height 0.3s;

    &-content {
      margin-bottom: $content-margin-bottom;
    }

    &-divider {
      background-color: $russian;
      border: 0;
      height: 1px;
      margin-bottom: 1.25rem;
      margin-top: 1.25rem;
    }
  }

  &__footer {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    &-start-adornment {
      max-width: 300px;

      &-icon {
        margin-right: 0.25rem;
      }

      &-text {
        color: $nevada;
        font-size: $font-sizes-small;
        font-weight: $font-weights-medium;
      }

      @media only screen and (max-width : $media-sizes-medium) {
        display: none;
      }
    }

    &-total {
      &-block {
        align-items: center;
        display: flex;
      }

      &-value {
        margin-left: 2rem;

        @media only screen and (max-width : $media-sizes-small) {
          margin-left: 1rem;
        }
      }
    }

    &-spacer {
      flex-grow: 1;
    }
  }
}
