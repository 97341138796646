@import '~@devsta/ui-kit/scss/core/vars';

.editable-comment {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 25px 25px 32px;
  width: 375px;

  &__header-content-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;
  }

  &__content-wrapper {
    margin-top: 10px;
    overflow: auto;
  }
}
