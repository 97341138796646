@import '../../../../scss/core/vars';

$input-height: 40px;
$arrow-btn-size: 19px;

.input-number {
  align-items: center;
  display: inline-flex;
  height: $input-height;

  &__text {
    align-items: center;
    border: 1px solid $russian;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    min-width: 60px;
    padding: 0 10px 0 15px;
    text-align: center;
    user-select: none;

    &__value {
      margin-right: 5px;
      min-width: 20px;

      > input {
        border: 0;
        outline: none;

        &:focus {
          border: 0;
          outline: none;
        }
      }
    }
  }


  &__arrow-btn {
    border: 0;
    border-radius: 0;
    height: $arrow-btn-size !important;
    max-height: $arrow-btn-size;
    min-height: $arrow-btn-size !important;
    min-width: $arrow-btn-size;
    width: $arrow-btn-size;

    &:hover,
    &:focus {
      border: 0;
      outline: none;
    }

    .icon-container {
      &:focus {
        border: 0;
        outline: none;
      }
    }

    &:first-of-type {
      .icon-container {
        margin-top: 5px;
      }
    }

    &:last-of-type {
      .icon-container {
        margin-bottom: 5px;
      }
    }
  }
}
