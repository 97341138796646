@import '~@devsta/ui-kit/scss/core/vars';

.requested-addons-view-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
  position: relative;
  top: 10px;

  &__addon-item {
    align-items: center;
    display: flex;
    height: 42px;
    justify-content: space-between;

    &__info {
      align-items: center;
      display: flex;
      justify-content: center;
      white-space: nowrap;

      &--name-icon {
        padding-left: 3px;
        padding-right: 19px;
      }

      &--description {
        overflow: hidden;
        padding-left: 0;
        white-space: nowrap;
        width: 50%;
      }

    }
  }
}
