@import '../../../../scss/core/vars';

$content-block-class: '.image-with-content';

.avatar {
  #{$content-block-class}__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: $font-sizes-default;
  }

  &__subtitle {
    color: $nevada;
    font-size: $font-sizes-small;
    margin-top: 5px;
  }
}
