@import '~@devsta/ui-kit/scss/core/vars';

.profile-menu {
  display: flex;
  height: 35px;
  justify-content: flex-end;
  padding-bottom: 4px;
  user-select: none;

  .menu-button {
    cursor: pointer;
  }

  &__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-width: 250px;

    > .dropdown-option + .dropdown-option {
      margin-top: 5px;
    }
  }

  &__userprofile-box {
    &__icon {
      align-items: flex-start;
      display: flex;
      margin-left: 10px;

      &__ghost {
        margin-right: 20px;
      }
    }
  }
}
