@import '~@devsta/ui-kit/scss/core/vars';

.addons-payment {
  $element-indent: 1.75rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 680px;

  .payment-section + .payment-section {
    margin-top: $element-indent;
  }

  &__foot-note {
    display: inline-block;
    font-size: $font-sizes-small;
    margin-top: 10px;

    > a {
      text-decoration: none;

      .text--link {
        display: inline-block;
        font-size: $font-sizes-small;
        font-weight: $font-weights-bold;
      }
    }
  }

  &__order-number {
    max-width: 300px;
    min-width: 150px;
    position: relative;
    top: 80px;
  }

  @media only screen and (max-width : $media-sizes-medium) {
    flex-direction: column;
    min-width: auto;

    &__order-number {
      margin-top: $element-indent;
      max-width: 100%;
      position: relative;
      top: 0;
      width: 100%;
    }
  }

}
