@import '~@devsta/ui-kit/scss/core/vars';

.manage-asset-modal {
  display: flex;
  flex-direction: row;
  height: 100%;
  @media only screen and (max-width: $media-sizes-medium) {
    flex-direction: column;
  }

  &__asset-info {
    border-bottom: 1px solid rgba($color: $russian, $alpha: 0.5);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    padding-bottom: 20px;
    width: 100%;
    @media only screen and (min-width: $media-sizes-medium) {
      flex-direction: column;
    }

    &__title {
      display: flex;
      margin-bottom: 10px;

      > .image {
        margin-right: 20px;
      }
    }
  }

  &__left-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    @media only screen and (max-width: $media-sizes-medium) {
      width: 100%;
    }
  }

  &__right-content {
    box-sizing: border-box;
    margin-left: 20px;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 40%;
    @media only screen and (max-width: $media-sizes-medium) {
      height: 70%;
      margin-left: 0;
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 20px;
      width: 100%;
    }
  }

  &__updated-by-block {
    align-items: center;
    color: $nevada;
    display: flex;
    font-size: $font-sizes-small;
    justify-content: space-between;
    letter-spacing: 1px;
    line-height: 17px;
    margin-top: 16px;

    &__download {
      margin-left: 20px;
    }

    .download-asset-menu {
      z-index: 3;
    }
  }

  &__asset-container {
    align-items: center;
    background-color: rgba($color: $white, $alpha: 0.5);
    border: 1px solid $russian;
    border-radius: 6px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    min-height: 25vh;
    width: 100%;
  }

  &__input-container {
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
    @media only screen and (max-width: $media-sizes-medium) {
      height: 70%;
    }

    > label {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }
}
