@import '~@devsta/ui-kit/scss/core/vars';

.player-resizer {
  height: 100%;
  max-height: 100%;
  position: relative;

  &__container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    > div {
      margin: auto;
      transition: max-width 0.2s ease-in-out;

      > .overlay-container {
        height: 100%;
      }
    }
  }
}
