.dropdown-filter {
  &__wrapper {
    position: relative;

    .dropdown-selected-items {
      margin-top: 20px;
    }
  }

  &__wrapper-decorator {
    > .icon-container {
      display: flex;
      height: 40px;
      position: absolute;
      width: 40px;
      z-index: 1;
    }

    .react-select {
      &__value-container {
        padding-left: 40px !important;
      }

      &__menu {
        z-index: 2;
      }
    }
  }
}
