@import '../../../../scss/core/vars';
@import '../../../common/styles/mixins/button';

.nav {
  &__menu {
    &-container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 20px;
      padding-top: 20px;

      .react-select {
        &__value-container {
          padding: 0 15px !important;
        }

        &__control {
          .dropdown-option {
            .icon-container {
              margin-right: 30px;
            }
          }
        }

        &__menu {
          .dropdown-option {
            .icon-container {
              border-radius: 2px;
              margin-right: 30px;
              padding: 1px;
            }

            &:hover {
              .icon-container {
                background-color: $cast;

                svg {
                  * {
                    fill: $white;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__primary-action {
      align-items: center;
      display: flex;
      margin: 0 0 30px 15px;
      overflow: hidden;
      white-space: nowrap;

      > .text {
        color: inherit;
      }

      &__btn {
        margin-left: 20px;

        > button {
          margin-right: 20px;
        }
      }
    }

    &__logo {
      margin-bottom: 50px;

      > button {
        align-items: center;
        display: flex;

        > .text {
          margin-left: 22px;
          text-align: left;
        }
      }
    }
  }
}
