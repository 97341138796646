@import '~@devsta/ui-kit/scss/core/vars';
@import '~draft-js/dist/Draft.css';
@import './headings';

.editor-controls {
  background-color: $white;
  border-left: 1px solid $russian;
  border-right: 1px solid $russian;
  border-top: 1px solid $russian;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: $font-sizes-default;
  padding: 20px;
  user-select: none;
  z-index: 2;

  .controls-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &__block {
    align-items: center;
    display: flex;
    height: 40px;
    padding: 0 15px;

    > * {
      cursor: pointer;
    }

    > *:not(:first-child) {
      margin-left: 15px;
    }
  }

  &__divider {
    border-left: 1px solid $russian;
    height: 25px;
    margin-top: 8px;
  }

  &__headings {
    margin-right: 20px;
    min-width: 230px;

    .dropdown-option {
      &__content {
        &__label {
          font-size: $font-sizes-small;
          font-style: normal;
          font-weight: $font-weights-medium;
          line-height: 17px;
        }
      }
    }

    &__item {
      @include headings;
    }
  }

  &__medium {
    padding-bottom: 10px;
    padding-right: 10px;

    .editor-controls {
      &__headings {
        flex: 1;
        margin: 0 10px 10px 0;
      }

      &__block {
        padding: 0 10px 10px 0;

        > *:not(:first-child) {
          margin-left: 5px;
        }
      }

      &__divider {
        display: none;
      }
    }
  }

  &__small {
    .editor-controls {
      &__headings {
        margin-bottom: 10px;
        min-width: calc(100% - 60px);
      }

      &__tools-button {
        background-color: $white;
        outline: none;

        &.is-active {
          background-color: $parthenon;
        }

        .icon-container {
          outline: none;
        };
      }

      &__tools-dropdown {
        background-color: $white;
        border: 1px solid $russian;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: 10px 20px;
        padding: 10px 0 0 10px;
        position: absolute;
        right: 0;


        > * {
          margin: 0 10px 10px 0;
        }
      }
    }
  }
}
