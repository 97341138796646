@import '../../../../scss/core/vars';

.drawer {
  &__overlay {
    background: rgba(0, 0, 0, 0.3);
    border: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    z-index: 3;

    &--visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  &--above-content {
    position: absolute;
    z-index: 10;
  }

  &--above-content.drawer {
    &--left-align {
      left: 0;
    }
  }

  &--above-content.drawer {
    &--right-align {
      right: 0;
    }
  }

  .tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .tabs {
    &__tab-content {
      overflow-y: scroll;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &--left-align &__menu {
    left: 0;
  }

  &--right-align &__menu {
    right: 0;
  }

  &__menu {
    z-index: 3;

    &__close {
      margin-left: auto;
      margin-right: 20px;
      margin-top: 20px;
    }

    &__expand-btn {
      align-items: center;
      background: $white;
      border: 1px solid $russian;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 25px;
      justify-content: center;
      opacity: 0;
      position: absolute;
      top: 65px;
      transition: opacity 0.2s ease-in-out;
      width: 25px;
      z-index: 1;

      &--visible {
        opacity: 1;
      }

      &:hover {
        background: $parthenon;
      }

      &:focus {
        background: $russian;
      }

      .icon-chevronleft {
        padding-right: 2px;
      }

      &--right-align {
        left: -12px;
      }

      &--left-align {
        right: -12px;
      }
    }
  }
}
