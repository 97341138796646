@import './data-table-mixins';

.data-table-body-row {
  $root: &;

  @include data-table-row-background($data-table-body-row-background-color);
  @include data-table-body-row-indicators($root);
  @include data-table-body-row-hovers($root);

  &--primary {
    @include data-table-body-row-spacer($root, $data-table-primary-body-row-indent-vertical);
    @include data-table-body-row-size($root, $data-table-primary-body-row-min-height);

    &:not(#{$root}--spacer) {
      @include data-table-row-shadow($data-table-primary-body-row-box-shadow);

      &#{$root}--root {
        @include data-table-row-rounded-top($data-table-primary-body-row-border-radius);

        &#{$root}--expanded {
          @include data-table-row-border-bottom(
            $data-table-primary-body-row-root-border-bottom-size,
            $data-table-primary-body-row-root-border-bottom-color
          );
        }
      }

      &#{$root}--child {
        &#{$root}--even {
          @include data-table-row-background($data-table-primary-body-row-child-background-color-even);
        }

        &#{$root}--odd {
          @include data-table-row-background($data-table-primary-body-row-child-background-color-odd);
        }
      }

      &#{$root}--last-visible {
        @include data-table-row-rounded-bottom($data-table-primary-body-row-border-radius);
      }
    }
  }

  &--secondary {
    @include data-table-body-row-spacer($root, $data-table-secondary-row-indent-vertical);
    @include data-table-body-row-size($root, $data-table-secondary-body-row-min-height);
  }

  &--expander {
    height: 100%;
  }
}
