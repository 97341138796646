@import '../../../../../../scss/core/vars';

.empty-data-container {
  padding: 85px;
  text-align: center;

  &--mobile {
    padding: 35px;
  }

  > .icon-container {
    margin-bottom: 15px;
  }

  &__search-term {
    color: $monkey;
    display: block;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
