@import '~@devsta/ui-kit/scss/core/vars';

.master-output {
  &__header {
    display: flex;
    margin-bottom: 40px;
  }

  &__heading {
    flex: 1;
  }

  &__addons {
    > .expandable-addon {
      border-bottom: 1px solid $russian;
      margin-bottom: 40px;
      padding-bottom: 40px;
    }
  }
}

@media screen and (max-width: $media-ranges-medium-min) {
  .master-output {
    &__header {
      .link {
        display: none;
      }
    }
  }
}
