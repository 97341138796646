@import '~@devsta/ui-kit/scss/core/vars';

.app-switcher-header {
  align-items: center;
  border-bottom: 1px solid $russian;
  box-sizing: border-box;
  display: flex;
  height: 64px;
  padding: 0 30px;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 30px;
  }

  .menu-button {
    &__menu {
      padding: 10px 0;
    }
  }

  .nav-menu-mobile {
    &__header {
      background: none;
      padding-left: 0;
      position: relative;
      width: 70px;

      > .icon-container:nth-of-type(2) {
        display: none;
      }
    }
  }

  &__mobile {
    border: 0;
  }

  &__organisation-btn {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    padding: 0;

    > .icon-container {
      display: flex;
      justify-content: center;
    }

    > .image {
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;
    }

    > .text {
      color: inherit;
      margin-left: 15px;
    }
  }

  &__administration {
    color: $nevada;
    flex: 1;
  }

  &__profile-btn {
    height: 35px;

    .menu-button {
      &__menu {
        padding: 10px 0;
      }
    }
  }

  &__app-menu-item {
    .text {
      &__prefix-icon {
        margin-right: 20px;
      }
    }
  }

  &__right-panel {
    align-items: center;
    display: flex;
    margin-left: auto;
    // react-sizeme adds empty last child to the header
    margin-right: 0 !important;

    > *:not(:last-child) {
      cursor: pointer;
      margin-right: 30px;
    }
  }
}
