@import '~@devsta/ui-kit/scss/core/vars';

.changes-form {
  &__body-title {
    margin-bottom: 20px;
  }

  &__reasons {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &__columns {
      columns: 2;

      @media only screen and (max-width : $media-sizes-medium) and (max-width : $media-sizes-medium) {
        columns: 1;
      }
    }

    &__item {
      margin-bottom: 15px;
    }
  }

  &__additional-reason textarea {
    resize: none;
  }
}
