@import '~@devsta/ui-kit/scss/core/vars';

.video-description {
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: $shadows-soft;
  display: flex;
  height: auto;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
	
  .video-container {
    background-color: $black;
    min-height: 200px;
    overflow: hidden;
    width: 55%;
  }

  .description-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;

    h3,
    p {
      width: 85%;
    }

    h3 {
      margin-top: -15px;
    }

    p {
      padding-bottom: 35px;
    }

    .video-description-cta {
      bottom: 25px;
      position: absolute;
      right: 35px;
    }

  }

  @media only screen and (max-width : $media-sizes-medium) {
    .description-container {
      p {
        padding-bottom: 45px;
      }

      h3 {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

  }

  @media only screen and (max-width : 900px) {
    flex-direction: column;
				
    .video-container {
      margin-bottom: 25px;
      min-height: 160px;
      width: 100%;
    }

    .description-container {
      padding-top: 0;
      width: 100%;

      h3,
      p {
        width: 78%;
      }

      p {
        padding-bottom: 60px;
      }
    }

  }

}
