@import '~@devsta/ui-kit/scss/core/vars';

.outputs-and-addons {
  box-sizing: border-box;
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 50px;

  &__section {
    background: $white;
    padding: 40px;

    &:not(:last-of-type) {
      margin-bottom: 40px;
      min-height: 770px;
    }
  }

  @media screen and (max-width: $media-sizes-small) {
    &__section {
      padding: 15px;
    }
  }
}
