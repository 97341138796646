// Colours
$black: #000;
$black-10: rgba(0, 0, 0, 0.1);
$black-25: rgba(0, 0, 0, 0.25);
$black-50: rgba(0, 0, 0, 0.5);
$black-75: rgba(0, 0, 0, 0.75);
$white: #fff;
$white-25: rgba(255,255,255,0.25);
$white-50: rgba(255,255,255,0.50);
$white-75: rgba(255,255,255,0.75);
$offwhite-35: rgba(220, 220, 240, 0.35);
$offwhite-15: rgba(249, 249, 251, 0.15);
$ghost-white: #F2F3F8;
$turquoise: #2BD0B3;
$aqua: #2ACFB3;
$aqua-dark: #469999;
$monkey: #27BAA1;
$parrot: #23A68F;
$monkey-light: #D4F2EC;
$bermuda-blue: #2A9F8B;
$cast: #4EAFB4;
$cod-grey: #111111;
$nero: #151515;
$coal-mine: #222222;
$mine-shaft: #262626;
$excavator: #2D2D2D;
$night-rider: #333333;
$denim: #323A45;
$light-denim: #4B5868;
$dark-denim: #1A1F24;
$gray-charcoal: #323A45;
$gray-charcoal-dark: #1A1f24;
$dark-denim-50: rgba(26, 31, 36, 0.5);
$dark-denim-75: rgba(26, 31, 36, 0.75);
$tundora: #474747;
$rasping-sandy: #555555;
$sterling: #EAEDF2;
$parthenon: #F4F6FA;
$zeus: #F5F6F8;
$big-fat: #E8ECF0;
$small-skinny: #CBDFF2;
$cat-skill-white: #FAFAFB;
$russian: #D4D9E2;
$michael: #CBD0DB;
$dusty: #979797;
$nevada: #696D74;
$jumbo: #7A7A7B;
$regent: #8C96A9;
$regent-50: rgba(140,150,169,0.5);
$thunder-bird: #BE1C22;
$alizarin-crimson: #DD1F26;
$red-apple: #BE1D22;
$golden-tainoi: #FFC850;
$dandelion: #FFDC64;
$golden-shiny: #FFCE44;
$green-snake: #6abd45;
$cerulean: #27BAA1;
$heartfelt-vermillion: #ff1100;
$unsafe-ochre: #F69F1F;
$snap-mint: #50ff03;
$shallow-orchid: #F118F8;
$archetypal: #0A84FF;
$horse-drawn-pansy: #A65AF2;
$gullible-platinum: #cffaf6;
$internal-pink: #FF3366;

// Font Sizes
$font-sizes-micro: 10px;
$font-sizes-small: 12px;
$font-sizes-default: 14px;
$font-sizes-phone: 16px;
$font-sizes-semilarge: 18px;
$font-sizes-large: 20px;

// Font Weights
$font-weights-regular: 400;
$font-weights-medium: 500;
$font-weights-semibold: 600;
$font-weights-bold: 700;

// Media Sizes
$media-sizes-small: 639px;
$media-sizes-medium: 1023px;
$media-sizes-large: 1600px;
$media-sizes-xlarge: 2048px;

// Media Ranges
$media-ranges-small-min: 0;
$media-ranges-small-max: 639px;
$media-ranges-medium-min: 640px;
$media-ranges-medium-max: 1023px;
$media-ranges-large-min: 1024px;
$media-ranges-large-max: 1600px;
$media-ranges-xlarge-min: 1601px;
$media-ranges-xlarge-max: 9999999px;

// Shadows
$shadows-default: 0 2px 4px 0 rgba(140, 150, 169, 0.5);
$shadows-soft: 0 6px 20px 0 rgba(13, 51, 32, 0.1);
$shadows-soft-elevated: 0 6px 20px 0 rgba(13, 51, 32, 0.2);
