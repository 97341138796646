@import '../../../../scss/core/vars';

.drawer-grid-list {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  .drawer-grid-list-header {
    align-items: center;
    background-color: $white;
    display: flex;
    height: 60px;
    justify-content: flex-start;
    position: relative;
    width: 100%;

    &--grid-list-icon {
      padding: 0 10px;
    }

    .text {
      white-space: nowrap; 
    }
  }

  .drawer-grid-list-body {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    left: 2px;
    list-style-type: none;
    margin: 0;
    margin-top: 30px;
    padding: 0;
    position: relative;
    width: 90%;

    &__item {
      align-items: center;
      display: flex;
      flex: 0 50%;
      height: auto;
      justify-content: center;
      margin: 0;
      margin-bottom: 0;
      padding: 0;
      position: relative;
      
      &__inner-item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 90%;
        height: auto;
        justify-content: flex-start;
        padding-bottom: 10px;
        width: 90%;

        p {
          font-size: $font-sizes-small;
          padding-left: 1px;
        }

        .item-image {
          background-color: $black-10;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 4px;
          height: 80px;
          position: relative;
          width: 100%;

          &__asset-type {
            bottom: 8px;
            height: 15px;
            position: absolute;
            right: 18px;
            width: 15px;
          }
        }

      }

      &--selected {
        background-color: $cat-skill-white;
        border: 1px solid $black-10;
        border-radius: 3px;
        cursor: pointer;
        height: 18px;
        left: 16px;
        position: absolute;
        top: 8px;
        width: 18px;
        z-index: 2;

        .icon-container {
          margin-top: 4px;
        }
        
      }
    }
  }

  &__empty-list {
    height: 400px;
    margin-top: 40px;
    position: relative;
    text-align: center;
    width: 90%;

    &__image {
      background-color: $black-10;
      background-image: url('../../../../assets/images/no-assets-selected.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      height: 200px;
      width: 100%;
    }

    h4 {
      justify-content: center;
    }

  }
}
