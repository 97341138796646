@import '../../../../scss/core/vars';
@import './data-table-mixins';

.data-table-cell {
  padding: 0;

  &--bold {
    font-weight: $data-table-cell-font-weight-bold;
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &#{&}--font-size-small {
    font-size: $data-table-cell-font-size-small;
  }

  &#{&}--font-size-semilarge {
    font-size: $data-table-cell-font-size-semilarge;
  }

  &__content {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 $data-table-cell-indent-horizontal;

    &--start {
      justify-content: start;
      text-align: start;
    }

    &--center {
      justify-content: center;
      text-align: center;
    }

    &--end {
      justify-content: end;
      text-align: end;
    }
  }
}
