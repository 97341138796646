@import '~@devsta/ui-kit/scss/core/vars';
@import '../../../../../common/styles/vars';

.expandable-addon {
  &__subtitle {
    margin-left: 40px;
    margin-top: 10px;
  }

  &__children {
    display: grid;
    grid-column-gap: 100px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: 10px;
    padding-left: 40px;
  }

  &__cost {
    margin-left: 40px;
  }

  &__main,
  &__checkbox {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  &__main {
    > div {
      display: flex;
      width: 100%;
    }
  }

  &__checkbox {
    flex-wrap: nowrap;
    white-space: nowrap;

    > p {
      margin-left: 20px;
    }
  }

  &__icon {
    margin-right: 10px !important;
  }

  &__info {
    cursor: pointer;
    margin-left: 10px;
  }

  &--small {
    .expandable-addon {
      &__children {
        grid-template-columns: repeat(auto-fill, 100%);
      }

      &__checkbox {
        > .text {
          margin-left: 40px;
        }
      }
    }
  }

  .react-select {
    &__multi-value {
      padding: 0;
      width: 0;
    }
  }

  .hide-default-value {
    white-space: nowrap;
    width: 100%;
  }

  .react-select {
    &__multi-value {
      padding: 0;
      width: 0;
    }
  }

  .rush-edit-container {
    margin-bottom: 25px;

    &__header {
      display: flex;

      .pending-status-text {
        color: $addon-status-color;
        font-style: italic;
        font-weight: $font-weights-medium;
        margin-left: 20px;
      }
    }

    &__radio-group {
      align-items: center;
      display: flex;
      margin: 15px 0 0 32px;

      .radio-button {
        margin: 0 50px 0 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .text--reason {
      color: #696d74;
      font-size: 12px;
      font-style: italic;
      font-weight: $font-weights-regular;
      margin: 15px 0 0 32px;
    }
  }

  @media screen and (max-width: $media-sizes-small) {
    &__children {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 425px) {
    position: relative;

    &__main {
      margin-right: 20px;

      > div {
        display: block;
      }
    }

    &__info {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
}
