@import '~@devsta/ui-kit/scss/core/vars';

.library {
  flex: 1;
  overflow: auto;

  .card-item,
  .list-item {
    .image {
      background-size: cover !important;
    }
  }
}

.stock-library {
  min-height: 1200px;

  .menu-button {
    &__menu {
      width: 260px;
    }
  }

  .filters {
    &__right-content {
      .icon-container:nth-of-type(3),
      .icon-container:nth-of-type(4) {
        display: none;
      }
    }
  }

  .xak,
  .xbo {
    grid-auto-rows: minmax(200px, max-content) !important;
  }

}

.asset-library-drawer {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 6;
}
