@import './data-table-variables';

@mixin data-table-outer-indent($top, $right, $bottom, $left) {
  margin: -#{$top} -#{$right} -#{$bottom} -#{$left};
  padding: $top $right $bottom $left;
}

@mixin data-table-row-rounded-bottom($radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;

  > .data-table-cell:first-child,
  > .data-table-cell:first-child::before { // before is needed to round row indicator borders
    border-bottom-left-radius: $radius;
  }

  > .data-table-cell:last-child,
  > .data-table-cell:last-child::before {
    border-bottom-right-radius: $radius;
  }
}

@mixin data-table-row-rounded-top($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;

  > .data-table-cell:first-child,
  > .data-table-cell:first-child::before {
    border-top-left-radius: $radius;
  }

  > .data-table-cell:last-child,
  > .data-table-cell:last-child::before {
    border-top-right-radius: $radius;
  }
}

@mixin data-table-row-border-top($size,  $color) {
  > .data-table-cell {
    border-top-color: $color;
    border-top-style: solid;
    border-top-width: $size;
  }
}

@mixin data-table-row-border-bottom($size,  $color) {
  > .data-table-cell {
    border-bottom-color: $color;
    border-bottom-style: solid;
    border-bottom-width: $size;
  }
}

@mixin data-table-row-border-left($size,  $color) {
  > .data-table-cell:first-child {
    border-left-color: $color;
    border-left-style: solid;
    border-left-width: $size;
  }
}

@mixin data-table-row-border-right($size,  $color) {
  > .data-table-cell:last-child {
    border-right-color: $color;
    border-right-style: solid;
    border-right-width: $size;
  }
}

@mixin data-table-body-rounded($size,  $color, $radius) {
  .data-table-body-row {
    @include data-table-row-border-left($size, $color);
    @include data-table-row-border-right($size, $color);

    &:first-child {
      @include data-table-row-border-top($size, $color);
    }

    &:last-child {
      @include data-table-row-border-bottom($size, $color);
    }

    &:first-child {
      @include data-table-row-rounded-top($radius);
    }

    &:last-child {
      @include data-table-row-rounded-bottom($radius);
    }
  }
}

@mixin data-table-row-rounded($radius) {
  @include data-table-row-rounded-bottom($radius);
  @include data-table-row-rounded-top($radius);
}

@mixin data-table-row-shadow($shadow) {
  box-shadow: $shadow;
}

@mixin data-table-row-size($min-height) {
  height: $min-height;

  .data-table-cell {
    height: $min-height;
  }
}

@mixin data-table-body-row-size($root, $min-height) {
  &#{$root}:not(#{$root}--spacer):not(#{$root}--expander) {
    @include data-table-row-size($min-height);
  }
}

@mixin data-table-body-row-spacer($root, $height) {
  &#{$root}--spacer {
    height: $height;
  }
}

@mixin data-table-body-row-indicator($indicator-color) {
  > .data-table-body-cell:first-child {
    position: relative;

    &::before {
      background: linear-gradient(to right, $indicator-color $data-table-row-indicator-width, transparent 0);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: $data-table-row-indicator-width;
    }
  }
}

@mixin data-table-body-row-indicators($root) {
  &--indicator-not-active {
    @include data-table-body-row-indicator($data-table-row-indicator-not-active-color);
  }

  &--indicator-warning {
    @include data-table-body-row-indicator($data-table-row-indicator-warning-color);
  }
}

@mixin data-table-body-row-hovers($root) {
  &#{&}:not(#{$root}--spacer) {
    &#{$root}--hoverable-available {
      @include data-table-body-row-hover($data-table-body-row-background-color-hover, pointer);
    }

    &#{$root}--hoverable-not-available {
      @include data-table-body-row-hover($data-table-body-row-background-color-hover, not-allowed);
    }
  }
}

@mixin data-table-row-background($color) {
  > .data-table-cell {
    background-color: $color;
  }
}

@mixin data-table-body-row-hover($color, $cursor) {
  &:hover {
    @include data-table-row-background($color);

    cursor: $cursor;
  }
}

@mixin data-table-header-row-sticky($color) {
  @include data-table-row-background($color);

  > .data-table-header-cell {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

