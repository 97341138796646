@import '../../../../scss/core/vars';

.advanced-filters {
  background-color: $white;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-left: auto;
  padding-bottom: 0;
  width: 130px;
  z-index: 2;

  &--compact-menu-button {
    width: 40px;
  }

  &__group__container {
    &--closed {
      padding-bottom: 0 !important;
    }
  }

  &__group__wrapper {
    > .check-box {
      margin-bottom: 20px;
    }

    &--closed {
      margin-bottom: 5px;
      max-height: 0;
      overflow: hidden;
    }

    &--opened {
      max-height: 500px;
    }

    .radio-button {
      margin-bottom: 17px;
    }
  }

  .menu-button {
    width: 100%;

    &__menu {
      z-index: 2;
    }
  }

  .text--link {
    font-weight: 500;
  }

  .check-box {
    &:hover {
      border-style: none !important;
    }

    &__checked {
      .text--body {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  .radio-button {
    &--selected {
      .text--body {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  > div > button {
    padding: 0;
    width: 100%;

    &__content {
      justify-content: space-between;
      padding: 0 15px;
      width: 100%;
    }
  }

  &__badge {
    background-color: $monkey;
    border-radius: 50%;
    color: $white;
    font-size: $font-sizes-small;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    margin-left: 5px;
    width: 20px;
  }

  &__header {
    margin-bottom: 10px;

    > p {
      font-weight: $font-weights-medium;
      margin: 0;
    }
  }

  &__group {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 15px;

      &--empty {
        justify-content: flex-end;
      }

      > h3 {
        font-size: $font-sizes-default;
        font-weight: $font-weights-medium;
        margin: 0;
      }
    }

    &__container {
      margin-bottom: 5px;

      > h4 {
        margin-bottom: 20px;
      }

      > .check-box {
        margin-bottom: 20px;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $michael;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $monkey;
      margin-bottom: 20px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $cat-skill-white;
  }

  &--medium {
    width: 100%;

    .inner-advanced-filters {
      &__header {
        align-items: center;
        display: flex;

        > * {
          margin-left: 3px;
          margin-right: 3px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

