@import '~@devsta/ui-kit/scss/core/vars';

.new-output {
  height: 40px;
  min-height: 40px;

  .text {
    font-weight: $font-weights-medium;
  }
}
