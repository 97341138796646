@import '~@devsta/ui-kit/scss/core/vars';

.player-options-menu {
  display: flex;
  justify-content: center;
  position: relative;

  .icon-container {
    margin-right: 15px;
    @media only screen and (max-width: $media-sizes-small) {
      margin-right: 5px;
    }
  }

  &__inner {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    bottom: 70px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: absolute;
    width: 8em;
    z-index: 10;

    > button {
      align-items: center;
      background: transparent;
      border: 0;
      color: $white;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: space-between;
      margin-bottom: 0;
      padding-bottom: 7px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 7px;
      text-align: left;
      width: 100%;

      > p {
        font-size: 11px;
        margin: 0;

        sup {
          color: #f00;
        }
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
