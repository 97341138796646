@import '../../../../scss/core/vars';

$input-height: 38px;

.input {
  &__editable {
    > input {
      background-color: inherit;
      border: 0 !important;
      font-size: $font-sizes-default;
      font-weight: inherit;
      height: $input-height;
      line-height: $input-height;
      max-width: inherit;
      outline: none !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

// Temporary fix for mobile zoom-in on input focus. Can be removed when default font-size is 16px.
@media only screen and (max-width: $media-sizes-medium) {
  .input {
    &__editable {
      > input {
        font-size: $font-sizes-phone;
      }
    }
  }
}
