@import '~@devsta/ui-kit/scss/core/vars';
@import '../../../common/styles/vars';

.addon-view {
  $icon-width: 25px;
  $icon-margin-right: 1rem;
  $icon-width-mobile: 20px;
  $icon-margin-right-mobile: 0.5rem;

  &__info {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    &__name {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      &--wrapped {
        align-items: flex-start;
        flex-direction: column;
      }

      &-block {
        display: flex;
      }

      &-icon {
        margin-right: $icon-margin-right;
        width: $icon-width;
      }

      &-description {
        flex-grow: 1;
        margin-left: calc(#{$icon-width} + #{$icon-margin-right});
      }

      @media only screen and (max-width : $media-sizes-small) {
        &-description {
          margin-left: calc(#{$icon-width-mobile} + #{$icon-margin-right-mobile});
        }

        &-icon {
          margin-right: $icon-margin-right-mobile;
          width: $icon-width-mobile;
        }
      }

      @media only screen and (max-width : $media-sizes-medium) {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    &__price {
      display: flex;
      justify-content: flex-end;
      min-width: 75px;
    }

    &__status {
      color: $addon-status-color;
      font-style: italic;
      font-weight: $font-weights-medium;
      line-height: inherit;
      margin-left: 20px;
    }
  }

  &__actions {
    display: flex;
    margin: 15px 0 20px calc(#{$icon-width} + #{$icon-margin-right});

    > * {
      margin-right: 60px;
    }
  }
}
