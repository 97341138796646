@import '~@devsta/ui-kit/scss/core/vars';

.not-found {
  align-items: center;
  background: $white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  &__text {
    max-width: 750px;

    > button {
      margin-top: 50px;
    }

    @media only screen and (max-width : $media-sizes-small) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__inner {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width : $media-sizes-small) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__icon {
    height: auto;
    margin-right: 50px;
    max-width: 250px;
    width: 40%;

    @media only screen and (max-width : $media-sizes-small) {
      margin-right: 0;
    }

    > svg {
      height: auto;
      width: 100%;
    }
  }

  &__suggest {
    margin-bottom: 0;
  }

  &__buttons {
    display: flex;
    margin-top: 5%;

    @media only screen and (max-width : $media-sizes-small) {
      margin-top: 10%;
    }

    > *:first-child:not(:last-child) {
      margin-right: 10px;
    }

    @media only screen and (max-width : $media-sizes-small) {
      flex-direction: column;

      > *:first-child:not(:last-child) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }

  &__header {
    margin-top: 5%;

    @media only screen and (max-width : $media-sizes-small) {
      margin-top: 10%;
    }
  }

  &__assist {
    align-items: baseline;
    margin-bottom: 2%;
    margin-top: 3%;
    text-align: center;

    > .text {
      display: inline;

      > strong {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}
