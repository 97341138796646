@import './data-table-mixins';

.data-table-body {
  &--secondary {
    @include data-table-body-rounded(
      $data-table-secondary-body-border-size,
      $data-table-secondary-body-border-color,
      $data-table-secondary-body-border-radius
    );
  }
}
