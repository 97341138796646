@import '~@devsta/ui-kit/scss/core/vars';

.payment-options-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  &__created-at {
    color: $denim;
  }
}
