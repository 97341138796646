@import '~@devsta/ui-kit/scss/core/vars';
$min-width: 385px;

.multiple-dropdown {
  &__item {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;

    &:first-child {
      margin-top: 0;

      button {
        display: none;
      }
    }

    .expandable-addon {
      &__children--content {
        margin-top: 21px;

        .input-container {
          min-width: $min-width;
        }
      }
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      margin-right: 10px;

      > div {
        display: flex;
      }
    }

    .dropdown--btn {
      margin-right: 14px;
      min-width: $min-width;
    }

    &-input--invalid {
      border-color: $thunder-bird;
    }
  }

  &__add-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 21px;
  }
}

@media screen and (max-width: $media-sizes-medium) {
  .multiple-dropdown {
    &__item {
      flex-direction: column;
      margin-left: 40px;

      &-container {
        flex-direction: column;
        margin-right: 0;
      }

      .expandable-addon {
        &__children--content {
          .input-container {
            min-width: 280px;
          }
        }
      }

      .dropdown--btn {
        margin-right: 14px;
        min-width: 280px;
      }

      .edit-cost {
        margin-top: 20px;
      }
    }
  }
}
