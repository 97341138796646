@import '~@devsta/ui-kit/scss/core/vars';

.collaboration-modal {
  .modal {
    &__content {
      overflow: hidden;
    }
  }

  &--loading {
    .tabs {
      &__tab-content {
        display: block;
      }
    }
  }

  &__body {
    overflow-y: auto;

    > .tabs {
      margin-top: 30px;
    }

    > .text {
      margin-top: 20px;
    }
  }

  &__header {
    margin: auto;
    margin-bottom: 50px;

    &__title {
      color: $black;
      font-size: 25px;
      letter-spacing: 2px;
      line-height: 30px;
      margin: 0;
      margin-bottom: 10px;
      text-align: center;
    }

    &__sub-title {
      color: $black;
      font-size: $font-sizes-default;
      letter-spacing: 1px;
      line-height: 20px;
      margin: 0;
      text-align: center;
    }
  }

  &__horizontal-line {
    border: 1px solid $russian;
    margin: 15px 0;
    opacity: 0.5;
    width: 100%;
  }
}
