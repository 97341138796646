@import '~@devsta/ui-kit/scss/core/vars';

$modal-actions-margin-top: 35px;
$modal-padding: 30px;
$modal-actions-height: 50px;
$estimated-total-height: 110px;
$estimated-total-mobile-height: 60px;

.payment-summary {
  &__divider {
    margin-bottom: 0;
    margin-top: 1.25rem;
  }

  .payment-section {
    &:first-child,
    &:last-child {
      .payment-section {
        &__body-content {
          margin: 0;
        }
      }
    }

    &:last-child {
      .payment-section {
        &__body {
          border: 0;
          padding: 0;
        }
      }

      .payment-section {
        &__footer-start-adornment {
          max-width: 350px;
          text-align: justify;
        }
      }
    }
  }

  .modal {
    &__content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: $estimated-total-height;
      min-width: 750px;

      @media only screen and (max-width : $media-sizes-medium) {
        margin-bottom: $estimated-total-mobile-height;
        min-width: auto;
      }
    }
  }

  .payment-section + .payment-section {
    margin-top: 1.75rem;
  }
}

.modal {
  padding: $modal-padding;
  position: relative;
}
