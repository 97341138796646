@import '~@devsta/ui-kit/scss/core/vars';

.voice-over-item {
  border-bottom: 1px solid $russian;
  display: flex;
  padding: 15px 0;

  &__col {
    width: 50%;
  }


  &__row {
    &_right {
      display: flex;
      justify-content: flex-end;
    }

    &:not(:first-child):last-child {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: $media-ranges-medium-min) {
  .voice-over-item {
    h4 {
      font-size: 16px;
    }

    p {
      font-size: $font-sizes-default;
    }
  }
}
