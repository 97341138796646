@import '~@devsta/ui-kit/scss/core/vars';

.changes-modal {
  &__title {
    color: $black;
    font-size: $font-sizes-large;
    font-weight: $font-weights-medium;
    margin-bottom: 15px;
    text-align: center;
  }

  &__subtitle {
    color: $black;
    margin-bottom: 25px;
    text-align: center;
  }
}
