@import '~@devsta/ui-kit/scss/core/vars';

.add-comment {
  .comment-popover {
    &__container {
      background-color: $white;
      border-radius: 12px;
      box-shadow: $shadows-soft;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow-y: inherit;
      padding: 20px 40px 40px;
      width: 500px;
    }

    &__icon-wrapper {
      align-items: center;
      border-radius: 17.5px;
      display: flex;
      height: 35px;
      justify-content: center;
      margin-right: 15px;
      width: 35px;
    }

    &__header {
      margin-bottom: 15px;

      &__title {
        align-items: center;
        display: flex;

        > h1 {
          font-size: $font-sizes-large;
          font-weight: $font-weights-medium;
          margin: 0;
        }
      }
    }

    &__body {
      display: flex;
      margin-bottom: 15px;
      overflow-y: hidden;

      > .rich-editor {
        display: flex;
      }

      > p {
        color: $regent;
        font-size: $font-sizes-small;
        font-weight: $font-weights-medium;
        line-height: $font-sizes-small;
        margin-bottom: 7px;
      }

      .rich-editor {
        &__editor {
          padding-right: 40px;
        }
      }

      &__icons-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
        width: 100%;

        > *:first-child {
          margin-right: 5px;
        }
      }
    }

    &__footer {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    &__close-icon {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 30px;
    }
  }
}
