@import '~@devsta/ui-kit/scss/core/vars';

.stock-assets-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: unset;

  &__list {
    border: 1px solid $russian;
    border-radius: 4px;
    flex: 1;
    max-height: 450px;
    max-width: 616px;
    overflow-y: scroll;
    
    &__asset {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      margin: 0 16px;
      padding: 16px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $russian;
      }

      .check-box {
        margin-right: 24px;
      }

      &__description {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        margin-left: 16px;
        min-width: 0;
      }
    }
  }

  &__summary {
    bottom: -75px;
    position: absolute;
  }

}

.modal .x35 {
  overflow: unset;
}
