@import '~@devsta/ui-kit/scss/core/vars';

.sign-up-banner {
  align-self: center;

  background: #26375a;
  background: linear-gradient(270deg, #26375a 0%, #27bba1 100%);
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 30px;

  &__header {
    color: $white;
    font-size: 24px;
    margin-right: 25px;
  }

  &__info {
    color: $white;
    flex: 1;
    font-size: 14px;
    margin-right: 25px;
  }

  &__button {
    align-self: center;
  }

  &--medium,
  &--small {
    background: linear-gradient(0deg, #26375a 0%, #27bba1 100%);
    flex-direction: column;
    padding: 25px;

    .sign-up-banner {
      &__header {
        align-self: center;
        flex: 1;
        font-size: 15px;
        margin-bottom: 20px;
      }
      
      &__info {
        display: none;
      }
  
      &__button {
        width: 100%;
      }
    }

  }

}
