@import '~@devsta/ui-kit/scss/core/vars';

.credits-used {
  position: relative;

  &__input-container {
    align-items: flex-end;
    display: flex;

    > div {
      width: auto;
    }

    > .text {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .text--label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .input-number {
    input {
      min-width: 15px;
    }
  }

  &__notes {
    margin-top: 35px;
  }

  .changes-form {
    &__additional-reason {
      margin-top: 35px;
    }
  }

  &__confirmation-note {
    margin-top: 25px;
  }

  &__save-btn {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__divider {
    background-color: $russian;
    border: 0;
    height: 1px;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 100%;
  }

  @media screen and (min-width: $media-sizes-medium) {
    width: 740px;
  }
}
