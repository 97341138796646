@import '~@devsta/ui-kit/scss/core/vars';

.menu-item {
  align-items: center;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  //justify-content: space-between;
  min-height: 45px;
  min-width: 250px;
  padding: 10px 20px;
  position: relative;
  width: 100%;

  &:hover {
    background: $parthenon;
  }

  &__separator {
    border: 0;
    border-bottom: 1px solid $dusty;
    box-sizing: border-box;
    height: 0;
    margin: 5px 20px;
  }

  &--selected {
    background-color: $sterling;
  }

  &--disabled {
    color: $sterling;
    cursor: default;
  }

  &__text-prefix-icon {
    margin-right: 25px;
  }
}

