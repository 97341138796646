@import '../../../../../../../scss/core/vars';

.card-item {
  background: $white;
  border-radius: 8px;
  box-shadow: $shadows-soft;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &--interactive {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  &--hovered {
    box-shadow: $shadows-soft-elevated;
    transform: translate3d(0, -4px, 0);
    transition-duration: 600ms;
    transition-property: box-shadow, transform;
    transition-timing-function: cubic-bezier(0.16, 1, 0.29, 0.99);
  }

  &--selected {
    &::after {
      border: 3px solid $monkey;
      border-radius: 8px;
      box-sizing: border-box;
      content: '';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }

  &__content {
    flex: 1;
    padding: 20px;
  }

  &__cover-image {
    height: 195px;
    width: 100%;

    > * {
      height: 100% !important;
      width: 100% !important;
    }

    .image {
      background-size: cover !important;
      border-radius: 0 !important;
      width: 100% !important;

      &__icon {
        height: 100%;
        width: 100%;
      }
    }

    .image,
    .asset-preview {
      height: 195px !important;
      min-height: 195px !important;
    }

    &--small {
      height: 140px;
      width: 100%;

      > * {
        height: 100% !important;
      }

      .image,
      .asset-preview {
        height: 140px !important;
        min-height: 140px !important;
      }
    }

    &--smallest {
      height: 100px;
      width: 100%;

      > * {
        height: 100% !important;
      }

      .image,
      .asset-preview {
        height: 100px !important;
        min-height: 100px !important;
      }
    }
  }
}
