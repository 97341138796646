@import './data-table-mixins';

.data-table-header-row {
  font-size: $data-table-header-cell-font-size;

  &--primary {
    @include data-table-row-size($data-table-primary-header-row-min-height);
  }

  &--secondary {
    @include data-table-header-row-sticky($white);
    @include data-table-row-size($data-table-secondary-header-row-min-height);
  }
}
