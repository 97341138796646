.comments-overlay-tooltip {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 5px 15px;
  transform: translateY(-50%);
  z-index: 3;
}

.comments-overlay-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  height: 100%;
  outline: inherit;
  padding: 0;
  width: 100%;

  &__crosshair {
    cursor: crosshair;
  }
}
