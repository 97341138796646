@import '~@devsta/ui-kit/scss/core/vars';

.comment {
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  min-height: 100px;
  padding: 10px 15px;

  &:hover {
    background: $parthenon;
  }

  &--group-selected {
    background-color: rgb(34, 34, 35);
  }

  &--comment-selected {
    border: 2px solid $aqua;
  }

  &__upper-container {
    &__heading {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &__right {
        margin-right: 10px;

        &__attachments {
          display: flex;
          margin-right: 10px;

          > .text {
            margin-right: 5px;
          }
        }
      }

      &__left {
        align-items: center;
        display: flex;
        width: 100%;

        &__number {
          align-items: center;
          border-radius: 50%;
          color: $white;
          display: flex;
          font-size: $font-sizes-micro;
          font-weight: bold;
          height: 25px;
          justify-content: center;
          line-height: 15px;
          margin-right: 5px;
          padding-left: 1px;
          width: 25px;
        }

        &__title {
          margin-left: 5px;
        }

        &__date {
          font-size: $font-sizes-default;
          font-weight: $font-weights-bold;
          margin-left: auto;
        }
      }

      .check-box {
        height: 20px;
        width: 20px;
      }
    }
  }
}
