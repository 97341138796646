@import '~@devsta/ui-kit/scss/core/vars';

.collapsed-banner {
  background: #26375a;
  background: linear-gradient(270deg, #26375a 0%, #27bba1 100%);
  bottom: 0;
  display: flex;
  flex: 1;
  left: 0;
  padding: 14px 40px;
  position: fixed;
  right: 0;

  &__header {
    align-self: center;
    color: $white;
    flex: 1;
    font-size: 18px;
  }

  &__button {
    align-self: center;
    
    &--text {
      cursor: pointer;
    }
  }

  &--small {
    padding: 16px;

    .collapsed-banner {
      &__header {
        align-self: center;
        flex: 1;
        font-size: 15px;
      }
    }
  }
}
