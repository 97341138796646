@import '~@devsta/ui-kit/scss/core/vars';

/* stylelint-disable */
.expandable-addon__dropdown-container {
  position: relative;

  .react-select__menu .dropdown-option .icon-container {
    margin-right: 0;
  }

  &.hide-default-value {
    .react-select__value-container--is-multi {
      height: 5px;
      opacity: 0;
      overflow: hidden;

      .react-select__multi-value {
        position: absolute;
        top: 20px;
      }
    }
  }

  .react-select__menu-list--is-multi {
    padding-top: 35px;

    .dropdown-option .icon-container {
      margin-right: 0;
    }

    &::before {
      color: $cerulean;
      content: 'Clear all';
      left: 19px;
      position: absolute;
      top: 13px;
      width: 100px;
      z-index: 100;
    }
  }

  .dropdown-option__content__label {
    margin-left: 10px;
  }

  .react-select__control .dropdown-option .icon-container {
    margin: 0;
  }

  .react-select__menu {
    z-index: 2;
  }

  .react-select__indicator {
    background: #fff;
    display: none;
    left: 0;
    position: absolute;
    top: 47px;
    width: 95%;
    z-index: 102;

    svg {
      display: none;
    }

    &::after {
      background: #fff;
      color: $cerulean;
      content: 'Clear all';
      left: 10px;
      position: relative;
      top: 5px;
    }
  }

  .react-select__control--menu-is-open {
    .react-select__indicator {
      display: block;
    }
  }

  .dropdown-option__suffix {
    font-size: $font-sizes-default;
  }

  .react-select__control .dropdown-option__suffix span,
  .check-box__checked ~ .dropdown-option__content p,
  .check-box__checked ~ .dropdown-option__suffix span {
    font-weight: $font-weights-bold;
  }
}

.dropdown-container__multi-items-value {
  display: flex;
  font-size: $font-sizes-default;
  font-weight: $font-weights-medium;
  justify-content: space-between;
  left: 15px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 11px;
  width: calc(100% - 55px);
  z-index: 1;

  > *:not(:first-child) {
    font-weight: $font-weights-bold;
    margin-left: 20px;
  }
}
