@import '../../../../scss/core/vars';
@import '../../../common/styles/mixins/button';

/* stylelint-disable */

.react-select__value-container--is-multi.react-select__value-container--has-value {
  /* stylelint-enable */
  padding-bottom: 5px;
  padding-top: 6px;
}

.react-select {
  &__value-container {
    .dropdown-option {
      &__content__label {
        font-weight: $font-weights-medium;
      }
    }
  }

  &__indicators {
    .dropdown-option {
      &__suffix {
        margin-right: 10px;
      }
    }
  }

  &__input > input {
    font-size: 14px !important;
    font-weight: $font-weights-regular;
    line-height: 22px;
  }

  &__control {
    &--menu-is-open {
      background-color: $sterling !important;
    }
  }

  &__value-container {
    min-height: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  &__menu-notice {
    &--no-options,
    &--loading {
      font-size: 14px !important;
      font-weight: $font-weights-regular;
      line-height: 22px;
    }
  }
}

.dropdown {
  &--btn {
    .react-select {
      &__control {
        @include button--tertiary;
      }

      &--is-disabled &__control {
        @include button--disabled;
      }
    }
  }

  &--btn.react-select--is-disabled {
    .react-select {
      &__control {
        @include button--disabled;

        .dropdown-option {
          &__content {
            &__label {
              color: $nevada;
            }
          }
        }
      }
    }
  }

  &--indicators-disabled {
    .react-select {
      &__indicators {
        display: none;
      }
    }
  }

  &--searchable {
    $root: '.react-select';

    // Have to redefine styles from drowpdown.js
    // To not break Dropdown styles redefine in other apps
    #{$root}__control {
      #{$root}__value-container--is-multi {
        > :last-child {
          margin: 0;
          position: relative !important;
          width: auto;

          > #{$root}__input {
            width: auto;

            > input {
              max-width: inherit;
            }
          }
        }
      }
    }
  }
}

// Temporary fix for mobile zoom-in on input focus. Can be removed when default font-size is 16px.
@media only screen and (max-width: $media-sizes-medium) {
  .react-select {
    &__input > input {
      font-size: $font-sizes-phone !important;
    }
  }
}