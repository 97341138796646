@import '~@devsta/ui-kit/scss/core/vars';

.edit-cost {
  &__container {
    align-items: center;
    display: flex;
    font-weight: $font-weights-semibold;
  }

  &__input-container {
    margin-left: 20px;
    position: relative;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* stylelint-disable property-no-vendor-prefix */
      -webkit-appearance: none;
      margin: 0;
    }

    .input-container {
      background-color: transparent;
      width: 100px;

      input {
        -webkit-appearance: textfield;
        padding-left: 30px;
      }
    }

    .currency-icon {
      align-items: center;
      display: flex;
      font-weight: $font-weights-regular;
      height: 40px;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      width: 30px;
    }
  }

  &__converted-value {
    margin-top: 20px;

    &--symbol {
      font-weight: $font-weights-semibold;
      padding-right: 35px;
    }
  }
}
